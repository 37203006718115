<template>
  <span class="badge bg-secondary" v-if="record.category === 'cash'">캐쉬</span>
  <span class="badge bg-secondary" v-if="record.category === 'money'">캐쉬</span>
  <span class="badge bg-success" v-if="record.category === 'turn'">게임</span>
</template>

<script>
export default {
  props: {
    record: { type: Object }
  },
  methods: {

  },
  computed: {

  }
}
</script>
