<template>
  <span class="badge bg-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'bet'"><i class="fa fa-book" v-if="record.message"></i>배팅</span>
  <span class="badge bg-success" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'win'"><i class="fa fa-book" v-if="record.message"></i>승</span>
  <span class="badge bg-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'lose'"><i class="fa fa-book" v-if="record.message"></i>패</span>
  <span class="badge bg-info" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'draw'"><i class="fa fa-book" v-if="record.message"></i>무</span>
  <span class="badge bg-secondary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'cancel'"><i class="fa fa-book" v-if="record.message"></i>취소</span>
  <span class="badge bg-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'receive'"><i class="fa fa-plus" v-if="record.message"></i>충전</span>
  <span class="badge bg-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'send'"><i class="fa fa-minus" v-if="record.message"></i>환전</span>
  <span class="badge bg-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'receive'"><i class="fa fa-plus" v-if="record.message"></i>충전</span>
  <span class="badge bg-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'send'"><i class="fa fa-minus" v-if="record.message"></i>환전</span>
  <span class="badge" :class="(record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter')) ? 'bg-danger':'bg-primary'" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'enter'"><i class="fa fa-book" v-if="record.message"></i>게임실행</span>
  <span class="badge bg-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'leave'"><i class="fa fa-book" v-if="record.message"></i>게임퇴장</span>
</template>

<script>
export default {
  props: {
    record: { type: Object }
  },
  methods: {

  },
  computed: {

  }
}
</script>
